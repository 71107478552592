<template>
	<div>
		<form  @submit.prevent="addCategory" ref="form" v-if="!addNewProductMode">
			<div class="wrapper_main footer-fixed">
				<main class="content">
					<div class="headline">
						<h1 class="headline__title">{{ $translate('add-a-category') }}</h1>
						<p class="headline__text">{{ $translate('here-you-can-create-new-category-and-add-products') }}</p>
					</div>
					<div class="tabs-wrapper">
						<ul class="tabs">
							<li class="tabs__item">
								<a class="nav-tab" @click="switchTab('info')" :class="isActiveTab('info')">{{ $translate('basic-info') }}</a>
							</li>
							<li class="tabs__item">
								<a class="nav-tab" @click="switchTab('devices')" :class="isActiveTab('devices')">{{ $translate('selected-products') }}</a>
							</li>
						</ul>
					</div>
					<div class="tab-content">
						<div id="info" class="tab-content__item" :class="isActiveTab('info')">
							<div class="addition">
								<div class="form-group form-group--wrapper">
									<div class="form-group__row">
										<div class="form-group__col-5 form-group__col">
											<div class="el-form">
												<label class="el-form__title">{{ $translate('category-name') }}</label>
												<input required type="text" class="input" v-model="name">
											</div>
										</div>								
									</div>
								</div>
							</div>
						</div>
						<div id="devices" class="tab-content__item" :class="isActiveTab('devices')">
							<div class="addition">
								<div class="headline headline--sm">
									<h2 class="headline__title">{{ $translate('selected-products') }}</h2>
									<p class="headline__text">{{ $translate('to-create-a-category-you-need-to-select-some-products.') }}</p>
								</div>
								<ul class="editable-list">
									<li class="editable-list__item" v-for="p in fieldValues('products')" :key="p.id">
										<div class="data-row">
											<ul class="data-row__list">
												<li class="data-row__item">{{p.id}}</li>
												<li class="data-row__item">{{p.title}}</li>
											</ul>
											<div class="data-row__controls">
												<button type="button" class="btn btn-icon red-color" @click="deleteFieldItem('products',p.id)">
													<inline-svg :src="require(`@/assets/img/Delete-circle.svg`)"/>
												</button>
											</div>
										</div>
									</li>
								</ul>
								<div class="buttons-group add-buttons">
									<WhitePlusButton @click="addProductsOpen"> {{ $translate('add-products') }}</WhitePlusButton>
									<WhitePlusButton @click="openAddNewCategory">{{ $translate('add-new-product') }}</WhitePlusButton>
								</div>
							</div>
						</div>
					</div>
				</main>

				<FooterTabsControl
					previous=1
					cancelRoute="/categories"
					:confirmName="$translate('add-category')"
					@confirm="addCategory"
					confirmType="submit"
					@swipeTab="swipeTab"
					:pageTabs="pageTabs"
					:activeTab="activeTab"
					:processing="processing"

				/>
				<!-- before translate confirmName="Add Category" -->

				<AddProducts
					v-if="addProductsPopupInfo.show"
					:products="addProductsPopupInfo.products"
					@close="addProductsClose"
					@submit="addProductsSubmit"
					:processing="aProcessing"
				/>

			</div>
		</form>
		<AddNewProduct 
			v-else
			@close="closeAddNewCategory"
			@confirm="confirmAddNewCategory"
		></AddNewProduct>

		<SuccessAction
			class="only_ok_and_text"
			v-if="successAddPopup"
			@close="successAddPopup = false"
			to="/categories"
			:message="$translate('category-successfully-added!')"
		></SuccessAction>
		<!-- before translate :message="'Category successfully added!'" -->
	</div>	
</template>

<script>
import {
	createCategory,
	updateCategory,
	updateProduct,
	createProductsCategory
} from '@/graphql/mutations'

import {
	listCategories
} from '@/graphql/queries'


import { API } from 'aws-amplify';


import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons";
import { mixTabs,mixProcessing,mixArrayFields } from "@/mixins";
export default {
	name: 'CategoriesAdd',
	components: {
        ...Base,
		...Popups,
		...Buttons
    },
	mixins: [mixTabs,mixProcessing,mixArrayFields],

	data() {
		return {
			name: '',
			products: [],
			addProductsPopup: false,

			//config tabs
			pageTabs:['info', 'devices'],
			//config add categories
			arrayFields:{
				products:[]
			},
			addNewProductMode:false,
			tabSave:null,
			successAddPopup: false,
		}
	},
	
	computed: {
		companyData() {
			return this.$store.state.companyData || {}
		},
		categoriesNames(){
			let categoriesStore= this.$store.state.categories || []
			return categoriesStore.map(c=>{
				return c.name
			})
		}
	},



	methods: {

		isNameValid(){
			return this.name.length>0 && this.categoriesNames.indexOf(this.name)===-1
		},
		async refreshData() {
			await this.$store.dispatch("GET_CATEGORIES");
			await this.$store.dispatch("GET_PRODUCTS");
			await this.$store.dispatch("GET_GROUPS"); 
			await this.$store.dispatch("GET_DEVICES");
		},
	  	switchTab(tab) {
			if(tab=='info'|| this.isNameValid()){
				this.activeTab = tab;
			}else{
				this.$refs.form.requestSubmit()
			}
        },
		openAddNewCategory(){
			this.addNewProductMode=true
			this.tabSave=this.activeTab
			this.switchTab('info')
		},
		closeAddNewCategory(){
			this.addNewProductMode=false
			this.switchTab(this.tabSave)
			this.tabSave=null
		},
		confirmAddNewCategory(newProduct){
			// console.log("n",newProduct);
			this.closeAddNewCategory()
			this.addFieldItems('products',[newProduct.id])
		},
		async addCategory() {
			// TODO: handle process
			if(!this.isNameValid()){
				this.switchTab('info')
				setTimeout(()=>{
					// this.$refs.form.requestSubmit()
				},500)
				return
			}
			this.processing=true
			let payload = {
				name: this.name,
				companyID: this.companyData.id,
			}
			const result = await API.graphql({
				query: createCategory,
				variables: {
					input: payload
				}
			}).then(res => res.data.createCategory);
			const requests = this.fieldArray('products').map((productID) =>
                API.graphql({
                    query: createProductsCategory,
                    variables: {
                        input: {
                            productID: productID,
                            categoryID: result.id,
                        },
                    },
                }).then((res) => res.data.updateProduct)
            );
            await Promise.all(requests);
			this.processing=false
            this.refreshData();
			
            // const options={title:'',size:'sm',okLable:"To Categories"} 
            // await this.$dialogs.alert("Category successfully added!", options).then(res=>{
            // })
			// this.$router.push('/categories');
			this.successAddPopup = true;
		},
	},

}
</script>

<style>

</style>